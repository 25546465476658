import React from "react";
import { useLocation } from 'react-router-dom'
import logo from '../assets2/logo70.webp'

const Contact = () => {

  const location = useLocation()
  if (location.pathname === '/drift'){
    console.log(location.pathname)
    window.location.replace('https://drift.szikszaimuvek.hu');
  }


    return (
        <>

      <footer className="py-8 footer footer-center p-4 bg-white dark:bg-slate-800 text-base-content">
    <div className='text-black dark:text-white'>
      <p>Copyright © 2023 - Minden jog fenttartva</p>
      <p>SERVERHU.EU - SUDO</p>
    <a href="https://serverhu.eu"><img alt='' src={logo} className="h-[70px]"></img></a>
    </div>
  </footer>


        </>
    )
};

export default Contact;
