import React from 'react'
import Carousel from "nuka-carousel"
import sendmessage from '../assets2/webp/banner-image-1-1920x500.webp'
import linedec from '../assets2/webp/line-dec.webp';
import grand from '../assets2/webp/grand.webp'
import rovidkocsi from '../assets2/webp/rovid.webp'
import hamarosanpng from '../assets2/webp/hamarosan.webp'
import logo from '../assets2/logo70.webp'

import limo1 from '../assets2/szolgaltatasok/limuzin1.webp'
import limo2 from '../assets2/szolgaltatasok/limuzin2.webp'
import limo3 from '../assets2/szolgaltatasok/limuzin3.webp'
import szolgaltatas1 from '../assets2/szolgaltatasok/gor-car-szolgaltatas-allapotfelmeres.webp'
import szolgaltatas2 from '../assets2/szolgaltatasok/vizsga.webp'
import szolgaltatas3 from '../assets2/szolgaltatasok/prémium-autófelmérés-02-e1605979498935.webp'
import szolgaltatas4 from '../assets2/szolgaltatasok/Automotive-Web.webp'
import szolgaltatas5 from '../assets2/szolgaltatasok/futomu.webp'
import szolgaltatas6 from '../assets2/szolgaltatasok/benikocsi.webp'
import szolgaltatas7 from '../assets2/szolgaltatasok/hasznalt.webp'
import szolgaltatas8 from '../assets2/szolgaltatasok/auto1.webp'
import szolgaltatas9 from '../assets2/szolgaltatasok/oil_change.webp'


const TirePricesTable = () => {
  const tireData = [
    { size: '175/65 R14', brand: 'FalkenHS02 Eurowinter', price: '23.100 Ft/db' },
    { size: '175/65 R14', brand: 'Kumho Wintercraft wp52', price: '21.150 Ft/db' },
    { size: '185/65 R15', brand: 'Laufenn Lw31 1 Fit', price: '21.660 Ft/db' },
    { size: '186/65 R15', brand: 'Kumho wp52 Wintercraft', price: '22.980 Ft/db' },
    { size: '195/65 R15', brand: 'Falken Hs02 Eurowinter', price: '23.950 Ft/db' },
    { size: '195/65 R15', brand: 'Laufenn Lw31 1 Fit', price: '21.660 Ft/db' },
    { size: '195/65 R15', brand: 'Hankook W462', price: '23.950 Ft/db' },
    { size: '196/65 R15', brand: 'Kumho wp52 Wintercraft', price: '23.750 Ft/db' },
    { size: '205/55 R16', brand: 'Laufenn Lw31 1 Fit', price: '29.120 Ft/db' },
    { size: '205/55 R16', brand: 'Barum Polaris 5', price: '29.880 Ft/db' },
    { size: '205/55 R16', brand: 'Hankook W462', price: '34.710 Ft/db' },
  ];

  return (
      <div className="py-12">
            <h1 className="text-white text-4xl font-black text-center mb-8">Téli<span className="text-orange-600"> Gumiárak</span></h1>
      <table className="min-w-full bg-white text-black dark:bg-slate-800 border border-gray-300 rounded-md overflow-hidden">
        <thead>
          <tr className="bg-[#EA580C] text-white">
            <th className="py-3 px-6 border-b border-gray-300">Méret</th>
            <th className="py-3 px-6 border-b border-gray-300">Márka</th>
            <th className="py-3 px-6 border-b border-gray-300">Ár</th>
          </tr>
        </thead>
        <tbody>
          {tireData.map((tire, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
              <td className="py-4 px-6 border-b border-gray-300">{tire.size}</td>
              <td className="py-4 px-6 border-b border-gray-300">{tire.brand}</td>
              <td className="py-4 px-6 border-b border-gray-300">{tire.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Services = () => {
    return (
    <>
    <div className="hero h-[300px]" style={{ backgroundImage: `url(${sendmessage})` }}>
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="w-full">
        <h1 className="mb-5 text-3xl font-black text-white">SZOLGÁL<span className="text-orange-600">TATÁSAINK</span></h1>
          </div>
        </div>
      </div>

      <TirePricesTable />


      <div className="hero h-[400px] bg-base-500">
        <div id="kocsik" className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-4xl text-black dark:text-white font-black">KIADÓ <span className="text-orange-600">AUTÓINK</span></h1>
            <div className="grid grid-cols-1">
             <img alt='' className="scale-[0.1] w-full" src="assets/images/line-dec.png"></img>
             
            </div>
          </div>
        </div>
      </div>
      
      
      
      <div className="grid gap-y-10 grid-cols-1 xl:grid-cols-3 place-items-center bg-white dark:bg-slate-800">
      
      <div className="card w-96 bg-base-100 shadow-xl bg-white dark:bg-slate-700">
        <figure><img src={grand} alt="Kocsi" /></figure>
        <div className="card-body">
          <h2 className="card-title text-black dark:text-white">
            Chrysler Grand Voyager 2.5 crd
          </h2>
          <p>&nbsp;&nbsp;&nbsp;</p>
          <div className="card-actions justify-end">
            <div className="badge badge-outline text-black dark:text-white"><i className="fa fa-cog"></i> Manuális váltó &nbsp;</div>
          </div>
        </div>
      </div>
    
      <div className="card w-96 bg-base-100 shadow-xl bg-white dark:bg-slate-700">
        <figure><img src={rovidkocsi} alt="Kocsi" /></figure>
        <div className="card-body">
          <h2 className="card-title text-black dark:text-white">
            Chrysler Voyager
          </h2>
          <p>&nbsp;&nbsp;&nbsp;</p>
          <div className="card-actions justify-end">
            <div className="badge badge-outline text-black dark:text-white"><i className="fa fa-cog"></i> Manuális váltó &nbsp;</div>
          </div>
        </div>
      </div>
    
      <div className="card w-96 bg-base-100 shadow-xl bg-white dark:bg-slate-700">
        <figure><img src={hamarosanpng} alt="Kocsi" /></figure>
        <div className="card-body">
          <h2 className="card-title text-black dark:text-white">
            Hamarosan további autók
          </h2>
          <p>&nbsp;&nbsp;&nbsp;</p>
          <div className="card-actions justify-end">
          </div>
        </div>
      </div>
    </div>


      <div className="hero h-[400px] bg-base-500">
        <div id="kocsik" className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-4xl text-black dark:text-white font-black">LIMUZIN <span className="text-orange-600">BÉRLÉS</span></h1>
            <div className="grid grid-cols-1">
             <img alt='' className="scale-[0.1] w-full" src={linedec}></img>
             <p className="text-2xl text-black dark:text-white">A limuzin bérlés csak esküvőkre és nagyobb eseményekre lehetséges.</p>
             
            </div>
          </div>
        </div>
      </div>
      

<div className='flex justify-center w-full py-2 gap-2'>
    <div className="carousel max-w-[500px] h-[700px]">
            <Carousel>
            <img alt='' src={limo1} />
            <img alt='' src={limo2} />
            <img alt='' src={limo3} />
            </Carousel>
        </div>

</div>

      <div className="hero h-[400px] bg-base-500">
        <div id="kocsik" className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-4xl text-black dark:text-white font-black">EGYÉB <span className="text-orange-600">SZOLGÁLTATÁSOK</span></h1>
            <div className="grid grid-cols-1">
             <img alt='' className="scale-[0.1] w-full" src={linedec}></img>
             
            </div>
          </div>
        </div>
      </div>
      
      
      
      <div className="grid gap-y-10 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 place-items-center">
      
        <div className="card w-96 bg-base-100 shadow-xl">
          <figure><img src={szolgaltatas1} alt="Kocsi" /></figure>
          <div className="card-body">
            <h2 className="card-title text-black dark:text-white">
              Autók átvizsgálása, Javítása
            </h2>
            <p>&nbsp;&nbsp;&nbsp;</p>
          </div>
        </div>
      
        <div className="card w-96 bg-base-100 shadow-xl">
          <figure><img className="scale-150" src={szolgaltatas2} alt="Kocsi" /></figure>
          <div className="card-body">
            <h2 className="card-title text-black dark:text-white">
              Műszaki vizsgára való felkészítés, Vizsgáztatás
              </h2>
              <p>&nbsp;&nbsp;&nbsp;</p>
            </div>
        </div>
      
        <div className="card w-96 bg-base-100 shadow-xl">
          <figure><img className="scale-125" src={szolgaltatas3} alt="Kocsi" /></figure>
          <div className="card-body">
            <h2 className="card-title text-black dark:text-white">
              Vásárlás előtt állapotfelmérés, Teljeskörű ügyintézés
            </h2>
            <p>&nbsp;&nbsp;&nbsp;</p>
          </div>
        </div>

        <div className="card w-96 bg-base-100 shadow-xl">
            <figure><img src={szolgaltatas4} alt="Kocsi" /></figure>
            <div className="card-body">
              <h2 className="card-title text-black dark:text-white">
                Időszakos felülvizsgálat
                </h2>
                <p>&nbsp;&nbsp;&nbsp;</p>
              </div>
          </div>

          <div className="card w-96 bg-base-100 shadow-xl">
            <figure><img src={szolgaltatas5} alt="Kocsi" /></figure>
            <div className="card-body">
              <h2 className="card-title text-black dark:text-white">
                Futómű javítás és beállítása
              </h2>
              <p>&nbsp;&nbsp;&nbsp;</p>
            </div>
          </div>
        

          <div className="card w-96 bg-base-100 shadow-xl">
            <figure><img className="" src={szolgaltatas6} alt="Kocsi" /></figure>
            <div className="card-body">
              <h2 className="card-title text-black dark:text-white">
                Drift autók szervízelése
              </h2>
              <p>&nbsp;&nbsp;&nbsp;</p>
            </div>
          </div>

          <div className="card w-96 bg-base-100 shadow-xl">
            <figure><img src={szolgaltatas7} alt="Kocsi" /></figure>
            <div className="card-body">
              <h2 className="card-title text-black dark:text-white">
                Használt és új alkatrészek beszerzése megrendelésre
              </h2>
              <p>&nbsp;&nbsp;&nbsp;</p>
            </div>
          </div>
          <div className="card w-96 bg-base-100 shadow-xl">
            <figure><img src={szolgaltatas8} alt="Kocsi" /></figure>
            <div className="card-body">
              <h2 className="card-title text-black dark:text-white">
                Autóbérlés, Csere autó
              </h2>
              <p>&nbsp;&nbsp;&nbsp;</p>
            </div>
          </div>
          <div className="card w-96 bg-base-100 shadow-xl">
            <figure><img src={szolgaltatas9} alt="Kocsi" /></figure>
            <div className="card-body">
              <h2 className="card-title text-black dark:text-white">
                Motorolaj csere
              </h2>
              <p>&nbsp;&nbsp;&nbsp;</p>
            </div>
          </div>


      </div>

      <div className="pt-12">
        <div className="hero h-[500px]" style={{ backgroundImage: `url(${sendmessage})` }}>
          <div className="hero-overlay bg-opacity-60"></div>
          <div className="hero-content text-center text-neutral-content">
            <div className="w-full">
              <h1 className="mb-5 text-5xl font-black text-white">KÜLDJ NEKÜNK <span className="text-orange-600">ÜZENETET</span></h1><br></br>
              <div className="text-white text-xl ">
                
              </div><a href="/kapcsolat">
                <button className="btn bg-[#EA580C] text-black hover:bg-[#bc3908]">Kapcsolat</button></a>
              </div>
            </div>
          </div>
        </div>

        <footer className="py-8 footer footer-center p-4 bg-white dark:bg-slate-800 text-base-content">
    <div className='text-black dark:text-white'>
      <p>Copyright © 2023 - Minden jog fenttartva</p>
      <p>SERVERHU.EU - SUDO</p>
    <a href="https://serverhu.eu"><img alt='' src={logo} className="h-[70px]"></img></a>
    </div>
  </footer>


    </>
    )
}

export default Services
