import React from "react";
import sendmessage from '../assets2/webp/banner-image-1-1920x500.webp'
import linedec from '../assets2/webp/line-dec.webp';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaFacebookSquare } from 'react-icons/fa'
import logo from '../assets2/logo70.webp'



const Contact = () => {
    return (
        <>


    <div className="hero h-[300px]" style={{ backgroundImage: `url(${sendmessage})` }}>
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="w-full">
          <h1 className="mb-5 text-5xl font-black break-words text-white">KERESS <span className="text-orange-600"> MINKET</span></h1>
          </div>
        </div>
      </div>

      <div className="hero h-[800px] bg-white dark:bg-slate-800">
        <div className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-4xl text-black dark:text-white font-black">KAPCSOLAT  <span className="text-orange-600">INFÓ</span></h1>
            <div className="grid grid-cols-1">
             <img className="scale-[0.1] w-full" alt="" src={linedec}></img>
             
            </div>


            <div className="relative py-20 px-4 sm:px-6 lg:px-8">
              <div className="text-center">
                <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white rounded-md shadow-sm flex justify-center items-center h-full py-4 px-6">
                        <div className="text-orange-600 scale-150">
                            <FaMapMarkerAlt></FaMapMarkerAlt>
                        </div>
                    </div>
                    <div className="bg-white rounded-md shadow-sm flex justify-center items-center h-full py-4 px-6">
                        <div className="text-orange-600 scale-150">
                            <FaPhoneAlt></FaPhoneAlt>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                    <span className="block text-orange-600 font-medium break-words text-center">1098 Bp. Kén utca 8</span>
                    <span className="block text-orange-600 font-medium break-all text-center">+36704217683</span>
                </div>
                <div className="pt-6"></div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-white rounded-md shadow-sm flex justify-center items-center h-full py-4 px-6">
                      <div className="text-orange-600 scale-150">
                            <FaEnvelope></FaEnvelope>
                        </div>
                  </div>
                  <div className="bg-white rounded-md shadow-sm flex justify-center items-center h-full py-4 px-6">
                    <div className="text-orange-600 scale-150">
                            <FaFacebookSquare></FaFacebookSquare>
                        </div>
                  </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                  <span className="block text-orange-600 font-medium text-center break-all"><a href="mailto:szikszaizo78@gmail.com">szikszaizo78@gmail.com</a></span>
                  <span className="block text-orange-600 font-medium text-center break-words"><a href="https://www.facebook.com/groups/766373203703307">Facebook csoportunk</a></span>
              </div>
              </div>
            </div>
            

          </div>
        </div>
      </div>


      <footer className="py-8 footer footer-center p-4 bg-white dark:bg-slate-800 text-base-content">
    <div className='text-black dark:text-white'>
      <p>Copyright © 2023 - Minden jog fenttartva</p>
      <p>SERVERHU.EU - SUDO</p>
    <a href="https://serverhu.eu"><img alt='' src={logo} className="h-[70px]"></img></a>
    </div>
  </footer>


        </>
    )
};

export default Contact;
