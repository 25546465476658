import React from 'react'
import Carousel from "nuka-carousel"
import sendmessage from '../assets2/webp/banner-image-1-1920x500.webp'
import linedec from '../assets2/webp/line-dec.webp';
import kep1 from '../assets2/muhely/1.webp'
import kep2 from '../assets2/muhely/2.webp'
import kep3 from '../assets2/muhely/3.webp'
import kep4 from '../assets2/muhely/4.webp'
import kep5 from '../assets2/muhely/5.webp'
import kep6 from '../assets2/muhely/6.webp'
import logo from '../assets2/logo70.webp'

const Pictures = () => {
    return (

        <>



<div className="hero h-[300px]" style={{ backgroundImage: `url(${sendmessage})` }}>
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="w-full">
          <h1 className="mb-5 text-3xl font-black text-white"><span className="text-orange-600">KÉPEK</span></h1>
          </div>
        </div>
      </div>




      <div className="hero h-[400px] bg-base-500">
        <div id="kocsik" className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-4xl text-black dark:text-white font-black">KÉPEK A <span className="text-orange-600">MŰHELYRŐL</span></h1>
            <div className="grid grid-cols-1">
             <img alt='' className="scale-[0.1] w-full" src={linedec}></img>
             
            </div>
          </div>
        </div>
      </div>



<div className='flex justify-center w-full py-2 gap-2'>
    <div className="carousel max-w-[500px] h-[700px]">
            <Carousel>
            <img alt='' src={kep1} />
            <img alt='' src={kep2} />
            <img alt='' src={kep3} />
            <img alt='' src={kep4} />
            <img alt='' src={kep5} />
            <img alt='' src={kep6} />
            </Carousel>
        </div>

</div>


<div className="">
  <div className="hero h-[500px]" style={{ backgroundImage: `url(${sendmessage})` }}>
    <div className="hero-overlay bg-opacity-60"></div>
    <div className="hero-content text-center text-neutral-content">
      <div className="w-full">
        <h1 className="mb-5 text-5xl font-black text-white">KÜLDJ NEKÜNK <span className="text-orange-600">ÜZENETET</span></h1><br></br>
        <div className="text-white text-xl ">
          
        </div><a href="/kapcsolat">
          <button className="btn bg-[#EA580C] text-black hover:bg-[#bc3908]">Kapcsolat</button></a>
        </div>
      </div>
    </div>
  </div>

  <footer className="py-8 footer footer-center p-4 bg-white dark:bg-slate-800 text-base-content">
    <div className='text-black dark:text-white'>
      <p>Copyright © 2023 - Minden jog fenttartva</p>
      <p>SERVERHU.EU - SUDO</p>
    <a href="https://serverhu.eu"><img alt='' src={logo} className="h-[70px]"></img></a>
    </div>
  </footer>



        </>

    )
}

export default Pictures
