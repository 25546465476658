import React from 'react';
import linedec from '../assets2/webp/line-dec.webp';
import herowebp from '../assets2/hero.webp';
import apng from '../assets2/webp/a.webp';
import grand from '../assets2/webp/grand.webp'
import rovidkocsi from '../assets2/webp/rovid.webp'
import hamarosanpng from '../assets2/webp/hamarosan.webp'
import olvassrolunkpng from '../assets2/webp/about-fullscreen-1-1920x700.webp'
import sendmessage from '../assets2/webp/banner-image-1-1920x500.webp'
import logo from '../assets2/logo70.webp'





const Home = () => {
    return (
    <>
    <div className="hero min-h-[600px]" style={{ backgroundImage: `url(${herowebp})` }}>
    <div className="hero-overlay bg-opacity-60"></div>
  <div className="hero-content text-center text-neutral-content">
    <div className="w-[100%]">
      <img className="scale-75 xl:scale-50 object-scale-down" alt='' src={apng}></img>
      <h1 className="mb-5 font-black text-orange-600 text-4xl md:text-5xl lg:text-6xl xl:text-7xl object-center text-center">SZIKSZAI MŰVEK <span className="text-white"><br></br> DÉL-PEST</span>
      </h1>
      <a href="/kapcsolat">
      <button className="btn bg-[#EA580C] text-black hover:bg-[#bc3908]">Kapcsolat</button></a>
    </div>
  </div>
</div>

<div className="hero h-[400px] bg-base-500">
  <div id="kocsik" className="hero-content text-center">
    <div className="max-w-md">
      <h1 className="text-4xl text-black dark:text-white font-black">KIADÓ <span className="text-orange-600">AUTÓINK</span></h1>
      <div className="grid grid-cols-1">
       <img className="scale-[0.1] w-full" alt='' src={linedec}></img>
       
      </div>
    </div>
  </div>
</div>


<div className="grid gap-y-10 grid-cols-1 xl:grid-cols-3 place-items-center bg-white dark:bg-slate-800">
      
  <div className="card w-96 bg-base-100 shadow-xl bg-white dark:bg-slate-700">
    <figure><img src={grand} alt="Kocsi" /></figure>
    <div className="card-body">
      <h2 className="card-title text-black dark:text-white">
        Chrysler Grand Voyager 2.5 crd
      </h2>
      <p>&nbsp;&nbsp;&nbsp;</p>
      <div className="card-actions justify-end">
        <div className="badge badge-outline text-black dark:text-white"><i className="fa fa-cog"></i> Manuális váltó &nbsp;</div>
      </div>
    </div>
  </div>

  <div className="card w-96 bg-base-100 shadow-xl bg-white dark:bg-slate-700">
    <figure><img src={rovidkocsi} alt="Kocsi" /></figure>
    <div className="card-body">
      <h2 className="card-title text-black dark:text-white">
        Chrysler Voyager
      </h2>
      <p>&nbsp;&nbsp;&nbsp;</p>
      <div className="card-actions justify-end">
        <div className="badge badge-outline text-black dark:text-white"><i className="fa fa-cog"></i> Manuális váltó &nbsp;</div>
      </div>
    </div>
  </div>

  <div className="card w-96 bg-base-100 shadow-xl bg-white dark:bg-slate-700">
    <figure><img src={hamarosanpng} alt="Kocsi" /></figure>
    <div className="card-body">
      <h2 className="card-title text-black dark:text-white">
        Hamarosan további autók
      </h2>
      <p>&nbsp;&nbsp;&nbsp;</p>
      <div className="card-actions justify-end">
      </div>
    </div>
  </div>
</div>

    <div className="grid grid-cols-1 place-items-center pt-10"><a href="/szolgaltatasok">
        <button className="btn bg-[#EA580C] text-black hover:bg-[#bc3908]">További szolgáltatások</button></a>
      </div>

<div className="pt-12">
<div className="hero h-[550px]" style={{ backgroundImage: `url(${olvassrolunkpng})` }}>
  <div className="hero-overlay bg-opacity-60"></div>
  <div className="hero-content text-center text-neutral-content">
    <div className="w-full">
      <h1 className="mb-5 text-5xl font-black text-white">Olvass <span className="text-orange-600">Rólunk</span></h1><br></br>
      <div className="text-white text-xl ">
      <p>Szikszai Zoltán vagyok, 1995 ben végeztem autószerelőként .
        Az autók iránti rajongásom pici korom óta tart. Hobbim  a munkám. 20 éve Chrysleres ügyfeleim szolgálatában állok !</p>
      </div>

      </div>
    </div>
  </div>
</div>

<div className="">
  <div className="hero h-[500px]" style={{ backgroundImage: `url(${sendmessage})` }}>
    <div className="hero-overlay bg-opacity-60"></div>
    <div className="hero-content text-center text-neutral-content">
      <div className="w-full">
        <h1 className="mb-5 text-5xl font-black text-white">KÜLDJ NEKÜNK <span className="text-orange-600">ÜZENETET</span></h1><br></br>
        <div className="text-white text-xl ">
          
        </div><a href="/kapcsolat">
          <button className="btn bg-[#EA580C] text-black hover:bg-[#bc3908]">Kapcsolat</button></a>
        </div>
      </div>
    </div>
  </div>


  <footer className="py-8 footer footer-center p-4 bg-white dark:bg-slate-800 text-base-content">
    <div className='text-black dark:text-white'>
      <p>Copyright © 2023 - Minden jog fenttartva</p>
      <p>SERVERHU.EU - SUDO</p>
    <a href="https://serverhu.eu"><img alt='' src={logo} className="h-[70px]"></img></a>
    </div>
  </footer>
    
    </>
    )
}

export default Home
