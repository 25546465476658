import Layout from './components/Layout'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './pages/Home'
import Pics from './pages/Pictures'
import Contact from './pages/Contact'
import Services from './pages/Services'
import Drift from './pages/Drift'

// import Profile from './pages/Profile'
// import Dashboard from './pages/Dashboard'
// import Course from './pages/Course'
// import Login from './pages/Login'

function App() {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/kapcsolat' element={<Contact />} />
                    <Route path='/kepek' element={<Pics />} />
                    <Route path='/szolgaltatasok' element={<Services />} />
                    <Route path='/drift' element={<Drift />} />




                    {/* <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/course' element={<Course />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/login' element={<Login />} /> */}
                </Routes>
            </Layout>
        </BrowserRouter>
    )
}

export default App
