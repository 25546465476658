import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { BsArrowLeftCircle, BsFillGrid1X2Fill, BsFillTelephonePlusFill } from 'react-icons/bs'
// import { AiFillPieChart } from 'react-icons/ai'
import { SiFuturelearn } from 'react-icons/si'
// import { SiOpenaccess } from 'react-icons/si'
// import { CgProfile } from 'react-icons/cg'
import { FaCamera } from 'react-icons/fa'
import Logo from '../assets2/logo50.webp'
import HamburgerButton from './HamburgerMenuButton/HamburgerButton'

const Sidebar = () => {
  const [open, setOpen] = useState(true)
  const [mobileMenu, setMobileMenu] = useState(false)
  const location = useLocation()

  const Menus = [
    { title: 'Kezdőlap', path: '/', src: <BsFillGrid1X2Fill /> },
    { title: 'Képek', path: '/kepek', src: <FaCamera /> },
    { title: 'Szolgáltatások', path: '/szolgaltatasok', src: <SiFuturelearn /> },
    { title: 'Kapcsolat', path: '/kapcsolat', src: <BsFillTelephonePlusFill /> },
    { title: 'Drift', path: '/drift', src: <BsFillGrid1X2Fill />, gap: 'true' },

    // { title: 'SudoThemeSide1', path: '/course', src: <SiFuturelearn />, gap: 'true' },
    // { title: 'SudoThemeSide2', path: '/profile', src: <CgProfile />},
    // { title: 'SudoThemeAuth', path: '/login', src: <SiOpenaccess /> },
  ]

  return (
    <>
      <div
        className={`${
          open ? 'w-60' : 'w-fit'
        } hidden sm:block relative h-auto duration-300 bg-gray-100 border-r border-gray-200 dark:border-gray-600 p-5 dark:bg-slate-800`}
      >
        <BsArrowLeftCircle
          className={`${
            !open && 'rotate-180'
          } absolute text-3xl bg-white fill-slate-800  rounded-full cursor-pointer top-9 -right-4 dark:fill-gray-400 dark:bg-gray-800`}
          onClick={() => setOpen(!open)}
        />
        <Link to='/'>
          <div className={`flex ${open && 'gap-x-4'} items-center`}>
            <img src={Logo} alt='' className='pl-2 w-12' />
            {open && (
                <span href='/' className="text-3xl text-black dark:text-white font-black">SZIKSZAI <span className="text-orange-600">MŰVEK</span></span>
            )}
          </div>
        </Link>

        <ul className='pt-6'>
          {Menus.map((menu, index) => (
            <Link to={menu.path} key={index}>
              <li
                className={`flex items-center gap-x-6 p-3 text-black font-normal rounded-lg cursor-pointer dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700
                        ${menu.gap ? 'mt-9' : 'mt-2'} ${
                  location.pathname === menu.path &&
                  'bg-gray-200 dark:bg-gray-700'
                }`}
              >
                <span className='text-2xl'>{menu.src}</span>
                <span
                  className={`${
                    !open && 'hidden'
                  } origin-left duration-300 hover:block`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      {/* Mobile Menu */}
      <div className="pt-3">
        <HamburgerButton
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
        />
      </div>
      <div className="sm:hidden">
        <div
          className={`${
            mobileMenu ? 'flex' : 'hidden'
          } absolute z-50 flex-col items-center self-end py-8 mt-16 space-y-6 font-bold sm:w-auto left-6 right-6 dark:text-white  bg-gray-50 dark:bg-slate-800 drop-shadow md rounded-xl`}
        >
          {Menus.map((menu, index) => (
            <Link
              to={menu.path}
              key={index}
              onClick={() => setMobileMenu(false)}
            >
              <span
                className={` ${
                  location.pathname === menu.path &&
                  'bg-gray-200 dark:bg-gray-700'
                } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
              >
                {menu.title}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export default Sidebar
